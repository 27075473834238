/* eslint-disable react-hooks/exhaustive-deps */
import styles from './style.module.css';
import logoLogin from '../../assets/svg/logo-login.svg';
import { INTERNAL_CODES, RESPONSE_CODES } from '../../config/response-codes';
import { getProfile } from '../../utils/getProfile';

// Components
import { Link, useNavigate } from 'react-router-dom';
import { Grid, Modal, Paper } from '@mui/material';
import CustomButton from '../../components/button.component';
import Captcha from '../../components/captcha.component';
import TextInput from '../../components/text-input.component';
import CustomAlert from '../../components/alert.component';
import Footer from '../../components/footer.component';
import Information from '../../components/information.component';
import { useLogin } from './hooks/useLogin';
import CustomModal from '../../components/modal.component';
import { ProfileChangePwdPage } from '../profile/profile-change-pwd';
import { Fragment, useEffect, useState } from 'react';
import { URLS_ENV } from '../../constants/urls.constants';

const Login = () => {
	const entorno = process.env.REACT_APP_ENV;
	const cadena = process.env.REACT_APP_TAG;
	const {
		data,
		errorNRP,
		errorCorreo,
		errorPwd,
		errorCaptcha,
		codeInput,
		refreshCaptcha,
		alert,
		modal,
		openModal,
		valueLogin,
		status,
		register,
		setCode,
		handleSetData,
		setCodeInput,
		handleValidate,
		setErrorCaptcha,
		setOpenModal,
	} = useLogin();
	const navigate = useNavigate();
	const [show, setShow] = useState<boolean>(false);

	useEffect(() => {
		if (getProfile()) {
			navigate('/inicio');
		}
	}, []);
	const closeModal = (location: string) => {
		if (location === 'login') {
			setOpenModal(false);
			setShow(false);
		}
	};
	const onHandle = () => {
		if (modal.labelButton === 'Cambiar contraseña') {
			setShow(true);
		}
	};
	useEffect(() => {
		if (entorno === 'qa') {
			const partes = cadena ? cadena.split('-') : '';
			if (partes.length >= 2) {
				const ultimoValor = partes[partes.length - 1];
				console.log(`v0.0.1_${ultimoValor}`); //eslint-disable-line
			}
		} else if (entorno === 'production') {
			console.log('front_v1.3.0_16.01.2025'); //eslint-disable-line
		}
	}, []);
	return (
		<Fragment>
			<Grid className={styles.subContainer} container spacing={0}>
				<Grid
					className={styles.mainContainerImgs}
					item
					xs={12}
					sm={12}
					md={7}
					lg={8}
				>
					<div className={styles.containerImgs}>
						<div className={styles.subContainerImgs}>
							<img
								src={logoLogin}
								className={styles.imgLogoInfonavit}
								alt="logo-infonavit"
							/>
						</div>
						<p className={styles.textInformation}>
							El Portal Empresarial te permite consultar información y hacer
							trámites en línea relacionados con tus trabajadores.
						</p>
					</div>
				</Grid>
				<Grid
					className={styles.mainContainerForm}
					item
					xs={12}
					sm={12}
					md={5}
					lg={4}
				>
					<Paper className={styles.containerForm}>
						<TextInput
							id="nrp"
							name="nrp"
							placeholder="01234567890"
							labelStyles={{ fontSize: 16 }}
							value={data.nrp.toLocaleUpperCase()}
							onChange={handleSetData}
							onBlur={handleSetData}
							labelComponent={
								<p className={styles.labelContainerInput}>
									Número de Registro Patronal
								</p>
							}
							error={!errorNRP.valid}
							helperText={!errorNRP.valid ? errorNRP.msg : ''}
						/>
						<TextInput
							id="correo"
							name="correo"
							placeholder="correo@electronico.com"
							labelStyles={{ fontSize: 16 }}
							value={data.correo}
							onChange={handleSetData}
							onBlur={handleSetData}
							labelComponent={
								<p className={styles.labelContainerInput}>
									Correo electrónico
								</p>
							}
							error={!errorCorreo.valid}
							helperText={!errorCorreo.valid ? errorCorreo.msg : ''}
						/>
						<TextInput
							id="pwd"
							name="pwd"
							label="Contraseña"
							placeholder="**********"
							type="password"
							labelStyles={{ fontSize: 16 }}
							value={data.pwd || ''}
							onChange={handleSetData}
							onBlur={handleSetData}
							labelComponent={
								<p className={styles.labelContainerInput}>Contraseña</p>
							}
							error={!errorPwd.valid}
							helperText={!errorPwd.valid ? errorPwd.msg : ''}
						/>
						<div className={styles.containerConsultNSS}>
							<Link to="/olvide-contrasenia" className={styles.labelLink}>
								¿Olvidaste tu usuario o contraseña?
							</Link>
						</div>
						<div className={styles.containerCaptcha}>
							<Captcha
								value={codeInput}
								getNew={refreshCaptcha}
								onChange={(newValue: string, codeCaptcha: string) => {
									setCodeInput(newValue);
									setCode(codeCaptcha);
								}}
								error={errorCaptcha}
								setError={setErrorCaptcha}
							/>
						</div>
						<div className={styles.containerButtonsForm}>
							{/* {valueLogin?.code !== '0003' && (
								<CustomAlert
									message={alert.message}
									severity={alert.severity}
									show={alert.show}
									hasIcon={alert.icon}
								/>
							)} */}
							<CustomButton
								onClick={handleValidate}
								variant="solid"
								label="Iniciar sesión"
							/>
							{/* {valueLogin?.code === '0003' && ( */}
							<CustomAlert
								message={alert.message}
								severity={alert.severity}
								show={alert.show}
								hasIcon={alert.icon}
								center={true}
							/>
							{/* )} */}
							<div className={styles.dividerButtons} />
							<CustomButton
								onClick={() => navigate('/crear-cuenta')}
								variant="outlined"
								label="Crear una cuenta"
							/>
							<div className={styles.linkContainer}>
								<Link
									to="/baja-representante-legal"
									className={`Text-default ${styles.labelLink}`}
								>
									Baja de un registro
								</Link>
								{process.env.REACT_APP_ENV !== 'production' && (
									<a
										className={`Text-default ${styles.labelLink}`}
										href={
											process.env.REACT_APP_ENV === 'qa'
												? URLS_ENV.LOGIN_A
												: URLS_ENV.LOGIN_B
										}
										target="_blank"
										rel="noreferrer"
									>
										Acceso CPA
									</a>
								)}
							</div>
						</div>
					</Paper>
				</Grid>
			</Grid>
			{(status === 'success' && valueLogin?.code !== RESPONSE_CODES.SUCCESSFUL) ||
			(status === 'error' &&
				valueLogin?.internalCode !== INTERNAL_CODES.SUCCESSFUL) ||
			register !== undefined ? (
				<CustomModal
					openModal={openModal}
					setOpenModal={setOpenModal}
					title={modal.title}
					type={modal.type}
					labelButton={modal.labelButton}
					closeButton={true}
					style={{ fontSize: 18, fontWeight: 400 }}
					callback={onHandle}
				>
					<Fragment>{modal.body}</Fragment>
				</CustomModal>
			) : (
				''
			)}

			<Modal
				open={show}
				onClose={() => closeModal('login')}
				style={{ overflowY: 'scroll', width: '100%' }}
			>
				<div className={styles.divChangePass}>
					<ProfileChangePwdPage
						setSelectedTab={() => {}}
						isLogin={true}
						data={{ ...data, password: data.pwd }}
						closeModal={closeModal}
					/>
				</div>
			</Modal>

			<Information />
			<Footer />
		</Fragment>
	);
};
export default Login;
