// Dependencies
import React, { ReactElement } from 'react';
import {
	ButtonOutlined,
	ButtonSolid,
	ButtonSolidRed,
} from '../styled-components/buttons.styled-components';

interface IButton {
	label?: string | React.ReactElement;
	onClick?: () => void;
	variant?: 'solid' | 'outlined' | 'solid-red';
	styles?: React.CSSProperties;
	disabled?: boolean;
	type?: 'button' | 'submit' | 'reset';
	children?: React.ReactNode;
	icon?: string;
	testId?: string;
}

const CustomButton = (props: IButton) => {
	const {
		onClick,
		children,
		label,
		variant = 'solid',
		styles,
		disabled,
		type,
		icon,
		testId,
	} = props;

	const getButtonType = (): ReactElement => {
		if (variant === 'solid') {
			return (
				<ButtonSolid
					onClick={onClick}
					style={{ ...styles, backgroundColor: disabled ? '#CDCDCD' : '' }}
					disabled={disabled}
					type={type}
					data-cy={testId}
				>
					{icon ? <img src={icon} alt="icon" /> : ''}
					{label}
					{children}
				</ButtonSolid>
			);
		}
		if (variant === 'solid-red') {
			return (
				<ButtonSolidRed
					onClick={onClick}
					style={{ ...styles, backgroundColor: disabled ? '#CDCDCD' : '' }}
					disabled={disabled}
					type={type}
					data-cy={testId}
				>
					{label}
				</ButtonSolidRed>
			);
		}
		return (
			<ButtonOutlined
				onClick={onClick}
				style={{ ...styles, backgroundColor: disabled ? '#CDCDCD' : '' }}
				disabled={disabled}
				type={type}
				id={label as string}
				data-cy={testId}
			>
				{icon ? <img src={icon} alt="icon" /> : ''}
				{label}
				{children}
			</ButtonOutlined>
		);
	};
	return getButtonType();
};

export default CustomButton;
