import { lazy, LazyExoticComponent } from 'react';

const SisubPage = lazy(() => import('../pages/sisub'));
const Linking = lazy(() => import('../pages/vinculacion'));
const CentralizedAttention = lazy(() => import('../pages/centralized-attention'));
const CreditProcedure = lazy(() => import('../pages/credit-procedure'));
const IntercommunicationServicesPage = lazy(
	() => import('../pages/intercommunication-services')
);
const ConstanciaSituacionFiscalPage = lazy(
	() => import('../pages/procedures/constancia-situacion-fiscal')
);
const TaxReceipt = lazy(() => import('../pages/tax-receipt'));

//Fase 2
const LestAchieveTogether = lazy(() => import('../pages/lets-achieve-together'));
//const PaymentMethodsPage = lazy(() => import('../pages/payment_methods'));

type JSXComponent = () => JSX.Element;

interface Route {
	path: string;
	to: string;
	private?: boolean;
	name: string;
	Component: LazyExoticComponent<JSXComponent> | JSXComponent;
	idSistema: string;
}

export const procedures: Route[] = [
	{
		to: '/mis-tramites/medios-de-pago',
		path: '/mis-tramites/medios-de-pago',
		name: 'Medios de pago',
		Component: Linking,
		private: true,
		idSistema: '12',
	},
	{
		to: '/mis-tramites/cumplamos-juntos',
		path: '/mis-tramites/cumplamos-juntos',
		name: 'Cumplamos juntos',
		Component: LestAchieveTogether,
		private: true,
		idSistema: '13',
	},
	{
		to: '/mis-tramites/aclaraciones-patronales',
		path: '/mis-tramites/aclaraciones-patronales',
		name: 'Aclaraciones patronales',
		Component: Linking,
		private: true,
		idSistema: '11',
	},
	{
		to: '/mis-tramites/comprobante-fiscal',
		path: '/mis-tramites/comprobante-fiscal',
		name: 'Comprobante fiscal',
		Component: TaxReceipt,
		private: true,
		idSistema: '15',
	},
	{
		to: '/mis-tramites/constancia-de-situacion-fiscal',
		path: '/mis-tramites/constancia-de-situacion-fiscal',
		name: 'Constancia de situación fiscal',
		Component: ConstanciaSituacionFiscalPage,
		private: true,
		idSistema: '16',
	},
	{
		to: '/mis-tramites/sistema-de-informacion-de-subcontratacion',
		path: '/mis-tramites/sistema-de-informacion-de-subcontratacion',
		name: 'SISUB',
		Component: SisubPage,
		private: true,
		idSistema: '22',
	},
	{
		to: '/mis-tramites/devoluciones-sua',
		path: '/mis-tramites/devoluciones-sua',
		name: 'Devoluciones SUA',
		Component: Linking,
		private: true,
		idSistema: '14',
	},
	{
		to: '/mis-tramites/atencion-centralizada',
		path: '/mis-tramites/atencion-centralizada',
		name: 'Atención Centralizada',
		Component: CentralizedAttention,
		private: true,
		idSistema: '28',
	},
	{
		to: '/mis-tramites/servicios-de-intercomunicacion',
		path: '/mis-tramites/servicios-de-intercomunicacion',
		name: 'Servicios de intercomunicación',
		Component: IntercommunicationServicesPage,
		private: true,
		idSistema: '19',
	},
	{
		to: '/mis-tramites/tramite-de-credito',
		path: '/mis-tramites/tramite-de-credito',
		name: 'Trámite de crédito',
		Component: CreditProcedure,
		private: true,
		idSistema: '23',
	},
	{
		to: '/mis-tramites/Asociacion-y-disociacion-de-nrp',
		path: '/mis-tramites/Asociacion-y-disociacion-de-nrp',
		name: 'Asociación y disociación de NRP',
		Component: Linking,
		private: true,
		idSistema: '17',
	},
];
